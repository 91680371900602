<template>
  <v-container fluid>
    <v-form
      class="mb-6"
      style="width: 100%"
      v-model="valid"
      ref="form"
      v-if="licenseTemplate"
    >
      <v-card>
        <div class="pa-2">
          <div class="d-flex flex-row">
            <div style="width: 50%" class="mx-2">
              <v-text-field
                :label="$t('name')"
                v-model="licenseTemplate.name"
                type="text"
                maxlength="256"
                :rules="formRules.required"
              />
              <v-text-field
                :label="$t('description') + ' (' + $t('optional') + ')'"
                v-model="licenseTemplate.description"
                type="text"
                maxlength="256"
                :rules="formRules.optional"
              />
              <v-text-field
                :label="$t('interval')"
                v-model="licenseTemplate.interval"
                type="number"
                :rules="formRules.required"
              />
              <v-checkbox
                v-model="licenseTemplate.renewable"
                :label="$t('renewable')"
                :false-value="0"
                :true-value="1"
              />
              <v-select
                :label="$t('clients')"
                v-model="licenseTemplate.client"
                :items="clients"
                :menu-props="{ maxHeight: '400' }"
                multiple
              />
              <v-select
                :label="$t('attached_scopes')"
                v-model="licenseTemplate.scope"
                :items="scopes"
                :menu-props="{ maxHeight: '400' }"
                multiple
              ></v-select>
              <v-select
                :label="$t('attached_roles')"
                v-model="licenseTemplate.role"
                :items="roles"
                :menu-props="{ maxHeight: '400' }"
                multiple
              ></v-select>
            </div>
          </div>

          <!-- errors -->
          <div class="d-flex flex-row" v-if="error">
            <span class="subtitle-1 mb-4 red--text"
              >{{ $t("form_invalid_text") }}: {{ error }}</span
            >
          </div>

          <!-- <actions> -->
          <div class="d-flex flex-row">
            <v-spacer />
            <v-btn class="ma-2" color="default" @click="cancel_click">
              <!-- <v-icon left>mdi mdi-plus</v-icon> -->
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              class="ma-2"
              color="primary"
              @click="save_click"
              :loading="loading"
            >
              <!-- <v-icon left>mdi mdi-plus</v-icon> -->
              {{ $t("save") }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;

export default {
  name: "LicenseTemplate",
  created: async function() {
    this.getScopes();
    this.getClients();
    this.getRoles();
  },
  data() {
    return {
      mode: null,
      licenseTemplate: null,
      licenseTemplate_orig: null,
      formRules: {
        required: [
          value =>
            typeof value === "undefined" || value == null || value == ""
              ? this.$t("field_required")
              : true
        ],
        requiredArray: [
          value =>
            typeof value === "undefined" ||
            value == null ||
            value == "" ||
            (Array.isArray(value) && value.length == 0)
              ? this.$t("field_required")
              : true
        ],
        optional: [value => true],
        optionalMin8: [value => (value ? value.length >= 8 : true)]
      },
      valid: true,
      error: null,
      loading: false,
      scopes: [],
      clients: [],
      roles: []
    };
  },
  watch: {
    "$route.params.licenseTemplateId": {
      immediate: true,
      handler(v) {
        if (v === "new") {
          this.mode = "create";
          this.createLicenseTemplate();
          return;
        }
        this.mode = "edit";
        this.loadLicenseTemplate(v);
      }
    }
  },
  methods: {
    createLicenseTemplate() {
      this.licenseTemplate = {
        name: null,
        description: null,
        renewable: 0,
        interval: 30,
        client: null,
        scope: null,
        role: null
      };
    },
    async loadLicenseTemplate(licenseTemplateId) {
      const url = `${this.$store.state.idp_api_url}/license-templates/${licenseTemplateId}`;
      const method = "GET";
      try {
        const response = await callAPI({ url, method });
        this.licenseTemplate = response.data;
        this.licenseTemplate_orig = this.cloneObj(response.data);
      } catch (err) {
        console.log(err);
      }
    },
    async cancel_click() {
      this.$router.go(-1);
    },
    async save_click() {
      this.error = null;

      this.valid = this.$refs.form.validate();
      if (!this.valid) {
        this.$dialog.error({
          title: this.$t("form_invalid_title"),
          text: this.$t("form_invalid_text"),
          actions: {
            true: this.$t("close")
          }
        });
        return;
      }

      let url = `${this.$store.state.idp_api_url}/license-templates`;
      if (this.mode == "edit") url += `/${this.licenseTemplate.templateId}`;

      const method = this.mode == "create" ? "POST" : "PATCH";

      const fields = [
        "name",
        "description",
        "renewable",
        "interval",
        "client",
        "scope",
        "role"
      ];

      let data = {};
      for (let field of fields) {
        if (this.licenseTemplate[field]) {
          data[field] = this.licenseTemplate[field];
        }
      }

      if (Object.keys(data).length === 0) {
        this.$router.go(-1);
        return;
      }

      try {
        this.loading = true;
        const response = await callAPI({ url, method, data });
        if (response.status == 204 || response.status == 200) {
          this.$router.go(-1);
        }
      } catch (err) {
        const data = err?.response?.data;
        if (data.statusCode == 400) {
          // this.error = this.$t(data.message)
          this.error = this.$t(data.validation.keys.join(", "));
        } else {
          this.errorSnackbar(err);
        }
      } finally {
        this.loading = false;
      }
    },
    async getScopes() {
      const result = await callAPI({
        url: this.$store.state.idp_api_url + "/scopes?limit=1000",
        method: "GET"
      });
      this.scopes = result.data.map(p => ({
        value: p._uri,
        text: p.scopeName
      }));
    },
    async getClients() {
      const result = await callAPI({
        url: this.$store.state.idp_api_url + "/clients?limit=1000",
        method: "GET"
      });
      this.clients = result.data.map(p => ({
        value: p._uri,
        text: p.client_name
      }));
    },
    async getRoles() {
      const result = await callAPI({
        url: this.$store.state.idp_api_url + "/permission-templates?limit=1000",
        method: "GET"
      });
      this.roles = result.data.map(p => ({
        value: p._uri,
        text: p.name
      }));
    }
  }
};
</script>
